body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.titlePage {
  display: grid;
  font-size: xx-large;
  text-align: center;
  justify-content: center;
  margin-top: 162px;
}

.right-text {
  color: white;
  text-align: right;
  justify-content: right;
  padding: 14px 16px;
}

.App {
  width: 100%;
}

.button-container {
  display: grid;
  justify-content: center;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.button_slide {
  color: #FFF;
  border: 2px solid rgb(216, 2, 134);
  border-radius: 8px;
  width: 70%;
  display: inline-block;
  padding: 18px;
  margin: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #D80286;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;

  a {
    text-decoration: none;
    color: #111;
  }
}

.slide_down:hover {
  box-shadow: inset 0 100px 0 0 #D80286;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover:not(.active) {
  background-color: #111;
}

.active {
  background-color: #1aaec2;
}